<template>
  <div class="games">   
    <div class="imgContent" :style="{backgroundImage:'url('+ bannerFn() +')'}">      
      <img v-if="screen" :src="bannerFn()" alt="">
      <img class="game" v-if="!screen" src="../../../public/img/en/3games-img/game.png" alt="">
    </div>
    <div 
      class="content"
      @mouseleave="mouseLStatus = false">
      <a
        href="javascript:;"
        v-for="(item,index) in gamesImg.games"
        :class="[{'enter':mouseStatus===index},{'leave':mouseLStatus===index}]"
        :key="'games_'+index"
        @mouseenter="mouseenterFn(index)"
        @mouseleave="mouseleaveFn(index)"
        @click="$router.push({path:'game',query:{id:item.games}})"
        >
        <img :src="item.url" alt="">
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      mouseLStatus: false,
      mouseStatus: false,
    }
  },
  computed: {
    ...Vuex.mapGetters([
      "gamesImg",
      "screen",
    ]),
  },
  methods: {
    bannerFn(){
      if(this.screen){
        let banner = this.gamesImg.banner;
        banner = banner.slice(0, banner.length - 4) + '2' + banner.slice(-4);
        return banner;
      }else{
        return this.gamesImg.banner;
      }  
    },
    mouseenterFn(index){
        this.mouseStatus = index;
    
    },
    mouseleaveFn(index){
      this.mouseStatus = false;
      this.mouseLStatus = index;
    }
  }
}
</script>
<style lang="stylus" scoped>
.games {
  & .imgContent{
    background-size: cover;
    background-position: center center;
    height: 656px;
    overflow: hidden;  
    position: relative;
    & img{
      width: 100%;  
    }
    & .game{
      width: 6.7rem;  
      margin: 4.15rem 0 0 .82rem;
    }
  }
}
.content{
  margin: .98rem auto 1.4rem;
  max-width: 12.32rem;  
  padding: 0 .16rem;
  flex()
  & a{
    width: 3.9rem;
    overflow: hidden;
    & img{
      width: 100%;  
    }  
    &:nth-child(2){
      margin: 0 .16rem;
    }
    &.enter{
      & img{    
        animation: gameScaleEnterKey 0.2s ease-in-out;
        -webkit-animation: gameScaleEnterKey 0.2s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.1s;
        -webkit-animation-delay: 0.1s;
      }
    }
    &.leave{
      & img{    
        animation: gameScaleLeaveKey 0.2s ease-in-out;
        -webkit-animation: gameScaleLeaveKey 0.2s ease-in-out;
        animation-fill-mode: forwards;
      }
    }
  }
}

@media screen and (max-width: 980px){
  .games{    
    & .imgContent{
      height: auto;
      & > img{
        width: 100%;
      } 
    }
  }
  .content{
    margin: .4rem 0;  
    flex(row,wrap,space-between,center)
    & a{
      margin: 0 0 .16rem;
      width: 48%;
      &:nth-child(2){
        margin: 0 0 .16rem 0;
      }
    }
  }  
}


@keyframes gameScaleEnterKey {
  from {
    tranform(scale(1));
  }
  to {
    tranform(scale(1.1));

  }
}

@keyframes gameScaleLeaveKey {
  from {
    tranform(scale(1.1));
  }
  to {
    tranform(scale(1));
  }
}

</style>